@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,600");
@import "https://s3.amazonaws.com/static.autofactpro.com/styles-qa/app.css";

html,
body,
#root {
  height: 100%;
  margin: 0;
}

.field-row {
  display: flex;
  justify-content: space-between;
}

.botton-row {
  padding-top: 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.loading-overlay {
  z-index: 999;
  position: absolute;
  background: rgba(0, 0, 0, 0.562);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.loading {
  position: relative;
  top: 30%;
}


.nav-item a {
  cursor: pointer;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand > img {
  width: 110px;
}

.icon-title {
  height: 25.5px;
  margin-right: 17px;
  font-size: 21px;
  color: #0cb7ef;
}

.btn-see-link {
  font-size: 12px;
  font-weight: bold;
  width: 44px;
  height: 25px;
  display: inline-block;
  text-align: center;
  padding-top: 4px;
  border-radius: 10%;
  border: none;
}

.btn-see-link:hover {
  text-decoration: none;
}

.main-wrapper-page {
  background: #fff;
  padding: 20px 40px;
  border-radius: 5px;
}
.main-wrapper-page .field-row,
.pre-admission-detail-page dl.row {
  padding: 20px 0;
  border-bottom: 1px dashed #e7eaec;
}

.pre-admission-detail-page dt {
  text-align: right;
}

.pre-admission-detail-page dd {
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  padding: 6px 12px;
  margin: 0;
  height: 35px;
}

.pre-admission-detail-page dd.no-border {
  border: none;
}

.pre-admission-form .form-group.row {
  margin-bottom: 0;
}

.pre-admission-form label {
  font-weight: bold;
}

.indicator {
  background-color: #55ccf4;
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
}

.indicator > .indicator-number {
  font-size: 12px;
  font-weight: bold;
  position: relative;
}

.massive-checkbox {
  margin-left: 10px;
}

.massive-view > .field-headers {
  font-size: 14px;
  color: #1e3c5a;
  border-bottom: 2px solid #d5dae0;
}

.field-headers > div > .massive-checkbox {
  margin-left: 25px;
}

.massive-view > .background {
  background-color: rgba(0, 0, 0, 0.03);
}

.massive-view > .data-row:hover {
  background-color: #f2f2f2;
}

.massive-view > .background-selected {
  background-color: #f2f2f2;
}

.notary-form select, .notary-form input {
  color: #93a3af;
}

.donwload-button {
  color: green !important;
}

.nav-tabs.tabs-light {
  background: #F2F2F2;
}

.nav-tabs.tabs-light .nav-item {
  color: #0CB7EF;
  font-weight: 500;
}
.nav-tabs.tabs-light .nav-link.active, .nav-tabs.tabs-light .nav-link:hover {
  background: #FFFFFF;
  border: none;
  color: #FF562E
}
.request-up-form label {
  color: #93a3af;
}
.request-up-form button {
  border: none;
}

.request-up-form .custom-file-label {
  height: 30px;
  padding: .375rem .75rem;
}
.request-up-form .custom-file-label:after {
  content:'Examinar' !important;
  margin-right: 5px;
  margin-top: 5px;
  height: 20px;
  background-color: #B1B1B1;
  color: #FFFFFF;
  font-size: 12px;
  padding: 0px 10px;
}

.sumary .no-request-up-box{
  border-left: #FF562E 8px solid;
}
.sumary .no-request-up-title{
  color: #FF562E;
}
.sumary .wait-aprove-box{
  border-left: #0CB7EF 8px solid;
}
.sumary .wait-aprove-title{
  color: #0CB7EF;
}
.sumary .signed-box{
  border-left: #13AD2B 8px solid;
}
.sumary .signed-title{
  color: #13AD2B;
}
.sumary .wait-repertory-box{
  border-left: #FFA401 8px solid;
}
.sumary .wait-repertory-title{
  color: #FFA401;
}
.sumary .wait-sign-box{
  border-left: #155892 8px solid;
}
.sumary .wait-sign-title{
  color: #155892;
}
.sumary .wait-receive-box{
  border-left: #8B4513 8px solid;
}
.sumary .wait-receive-title{
  color: #8B4513;
}
.sumary .contract-to-enter-box{
  border-left: #FFB6C1 8px solid;
}
.sumary .contract-to-enter{
  color: #000000;
}
.sumary .summary-box{
  background-color: #F5F6F7;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 12px;
}
.sumary .sumary-title {
  font-size: 41px;
  margin-top: 12px;
  font-family: Roboto;
  font-weight: 100;
}
.sumary .sumary-description{
  color:#555555;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
}
.sumary .show-more{
  color:#155892;
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  padding: 5px;
  background-color: #FFFFFF;
  margin-top: auto;
  cursor: pointer;
  width: 65px;
}
.none {
  display:none !important;
}
.general-summary-title{
  color: #555555;
  font-size: 20px;
  font-family: Raleway;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}
.general-summary-title strong{
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}
.general-summary-title .icon {
  color:#C7C7C7
}
.no-show { display:none; }
.request-upload-list-title{
  font-size: 16px;
  font-family: Raleway;
  font-weight: 600;
}

.mark-as-entered {
  padding: 20px;
}

.not-show {
  display: none;
}

.remote-video video{
  width: 320px;
  height: 320px;
  visibility: visible!important;
}


.identity-meet-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.local-video {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.local-video video {
  object-fit: contain;
  width: 320px;
  margin: 0 auto;
}

.remote-video {
  position: absolute;
  bottom: 10px;
  right: 30px;
  width: 40%;
  overflow: hidden;
}

.remote-video video {
  object-fit: contain;
  width: 100%;
}




.alignCenter {
  text-align: center !important;
}

.btn:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.span-link {
  cursor:pointer;
  color: #0cb7ef;
  text-decoration: none;
  background-color: none;
}

.main-container {
  padding: 20px 20px;
  min-height: 710px;
  background: #f2f2f2;
}

.main-containerMod {
  display:flex;
  flex-grow: 2;
  padding: 10px 10px;
  background: #f2f2f2;
}

.loginDiv{
  width: 30%;
  margin: auto;
  background-color: white;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5%;
  max-width: 700px;
  min-width: 200px;
  flex-grow:2;
}
